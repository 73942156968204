
              @import "@/assets/css/variables.scss";
            
























@import '@/assets/css/pages/deposit/logo.scss';
@import '@/assets/css/pages/depositFunds.scss';

@media (min-width: 550px) {
  /deep/ .common-dialog-wrapper .el-dialog__body p {
    padding: 0 16% !important;
  }
}

.text-result {
  margin-top: 12px;
  margin-bottom: 24px;
}
.text-link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline !important;
  }
}
.submit-btn {
  margin-bottom: 16px;
  min-width: 180px;
}

.alert-container {
  background-color: $blue;
  min-height: 60px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desc {
    color: $white;
    font-size: 22px;
  }
  .alert-link {
    color: $white;
    text-decoration: underline;
    &:hover {
      text-decoration: underline !important;
    }
  }
  .alert-btn {
    text-transform: capitalize;
    font-weight: 600;
    padding: 12px 20px;
  }

  &.mobile {
    flex-direction: column;
    .desc {
      font-size: 16px;
    }
    .alert-btn {
      margin-top: 12px;
    }
  }
}
